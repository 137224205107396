 <div class="hori-timeline p-0 m-0 mt-3 mb-3" dir="ltr">
    <ul class="list-inline events p-4">
        <li class="list-inline-item event-list px-4" *ngFor="let item of
            itemTimeline">
            <div class="bg-timeline" style="background-image:
                url('{{item.image}}');"></div>
            <p class="timeline-date-title">{{item.titulohistory}}</p>
            <p class="timeline-date">{{item.date}}</p>
        </li>
    </ul>
</div>