import { Injectable } from '@angular/core';
import { HttpsService } from '../../../services/https.service'

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private https:HttpsService) { }

  getCompany(){
    return this.https.get({
      url: `ws/business/?limit=1`
    });
  }
}
