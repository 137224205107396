<router-outlet></router-outlet>

<ng-template #modaladult let-modal>

  <div class="modal-body">
    <form [formGroup]="formRequest" (ngSubmit)="send()">
      <div class="row mt-2">
        <div class="col-12">
          <div class="logo-modal-adult" align="center">
            <img [src]="logo" alt="logo-modal-adult">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <h1 class="title-modal-adult">¿Es usted mayor de edad?</h1>
        </div>
      </div>
      <div class="row mt-2 pr-3 pl-3">
        <div class="col-6" align="center">
          <input class="d-none" id="no_radio" type="radio"
            formControlName="checked" value="no">
          <button type="submit" class="btn btn-outline-dark p-0
            {{formRequest?.get('checked')?.value == 'no' ? 'active' : ''}}" style="cursor: pointer;">
            <label class="m-0 pl-4 pr-4 pb-3 pt-3" for="no_radio">No</label>
          </button>
        </div>
        <div class="col-6" align="center">
          <input class="d-none" id="si_radio" type="radio"
            formControlName="checked" value="yes">
          <button type="submit" class="btn btn-outline-primary p-0
            {{formRequest?.get('checked')?.value == 'yes' ? 'active' : ''}}" style="cursor: pointer;">
            <label class="m-0 pl-4 pr-4 pb-3 pt-3" for="si_radio">Si</label>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>