import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Metodo {
  url: string,
  data?: object,
  header?: HttpHeaders,
  port?: number
}

export interface iToken {
  access: string,
  refresh: string
}

@Injectable({
  providedIn: 'root'
})
export class HttpsService {

  public idProduct: number = null;
  public idCocktail: number = null;
  public idSubcategory: number = null;
  
  private baseurl: string = environment.baseurl;
  constructor(public http: HttpClient) { }

  get(method: Metodo): Observable<any> {
    return this.http.get(`${this.baseurl}${method.url}`, {});
  }

  post(method: Metodo): Observable<any> {
    return this.http.post(`${this.baseurl}${method.url}`, method.data, { headers: method.header });
  }

  patch(method: Metodo): Observable<any> {
    return this.http.patch(`${this.baseurl}${method.url}`, method.data, { headers: method.header });
  }

  put(method: Metodo): Observable<any> {
    return this.http.put(`${this.baseurl}${method.url}`, method.data, { headers: method.header });
  }

  delete(method: Metodo): Observable<any> {
    return this.http.delete(`${this.baseurl}${method.url}`);
  }

  getGenereteToken(method: Metodo = {
    url: 'api/token/',
    data: {
      username: 'admin',
      password: 'admin'
    }
  }) {
    return this.http.post(`${this.baseurl}${method.url}`, method.data);
  }
}
