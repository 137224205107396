import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
const YES = 'yes';
const NO = 'no';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'appilm';
  private adultAccept: boolean;
  public closeResult = '';
  public logo: string = "https://www.qualityapps.com.co:8010/static/media/image001_2.png";
  @ViewChild('modaladult') public modaladult: ElementRef;

  public formRequest = new FormGroup({
    checked: new FormControl(YES, Validators.required),
  });

  constructor(private modalService: NgbModal) {
    this.adultAcceptModal();
  }

  adultAcceptModal() {
    this.adultAccept = Boolean(localStorage.getItem('adultAccept'));
    if (!this.adultAccept) {
      setTimeout(() => {
        this.open(this.modaladult);
      }, 1);
    }
  }

  open(content: ElementRef) {
    this.modalService.open(
      content,
      {
        backdropClass: 'backdrop-modal-adult',
        backdrop: 'static',
        // size: 'lg',
        animation: true,
        centered: true,
        windowClass: 'bg-modal-adult'
      }
    );
  }

  send() {
    if (this.formRequest.invalid) return alert('Seleccione una opcion');
    const checked = this.formRequest.get('checked').value;
    if (checked == YES) {
      localStorage.setItem('adultAccept', 'true');
      this.modalService.dismissAll();
    } else {
      window.location.reload();
    }
  }
}


