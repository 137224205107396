import { Component, OnInit } from '@angular/core';
import {FooterService} from './services/footer.service';
import {BusinessModel} from './models/businessModel'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public test: Date = new Date();
  public data: Array<BusinessModel>=[];
  constructor(private footerService: FooterService) { }

  ngOnInit(): void {
    this.getBusiness()
  }

  getBusiness(){
    this.footerService.getCompany().subscribe(
      res => {
        res = <Array<BusinessModel>>res.results;
        if (res && res.length > 0) {
          this.data = res;
        }
      },
      err => {
        alert({ type: 'danger', message: 'Error al obtener informacion de la empresa' });
      }
    );
  }
}
