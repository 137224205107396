import { Component, OnInit } from '@angular/core';
import { BusinessModel } from '../footer/models/businessModel';
import { FooterService } from '../footer/services/footer.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public isCollapsed: boolean = true;
  public data: Array<BusinessModel>=[];
  public logo: string = "https://www.qualityapps.com.co:8010/static/media/image001_2.png";
  
  constructor(private footerService: FooterService) { }

  ngOnInit(): void {
  }

  getBusiness(){
    this.footerService.getCompany().subscribe(
      res => {
        res = <Array<BusinessModel>>res.results;
        if (res && res.length > 0) {
          this.data = res;
          this.logo = this.data[0].logo;
        }
      },
      err => {
        alert({ type: 'danger', message: 'Error al obtener informacion de la empresa' });
      }
    );
  }
}
