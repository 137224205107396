<nav class="navbar fixed-top navbar-expand-lg navbar-light nav-blur">
    <div class="navbar-brand pt-3 d-flex align-items-center pl-lg-4">
        <img [src]="logo" alt="logo-ilm" class="img-logo-ilm">
        <p class="text-navbar-ilm">Industria Licorera del Magdalena</p>
    </div>
    <button class="navbar-toggler" type="button" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <ul class="navbar-nav navbar-nav-ilm">
            <li class="nav-item p-lg-2" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <a class="nav-link" [routerLink]="['/']">Home <span
                        class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item p-lg-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/nuestros-productos']">Productos</a>
            </li>
            <li class="nav-item p-lg-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/cocteles']">Cocteles</a>
            </li>
            <li class="nav-item p-lg-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/nosotros']">Nosotros</a>
            </li>
            <li class="nav-item p-lg-2" routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/donde-comprar']">¿Dónde
                    comprar?</a>
            </li>
        </ul>
    </div>
</nav>