<div class="row p-1 m-0">
    <div class="col-12 p-0 footer-flex">
        <div>
            <p class="text-title-footer"> {{data[0]?.fullName}}</p>
            <p class="text-title-footer">NIT: {{data[0]?.identification}}</p>
        </div>
        <div>
            <p class="text-footer">
                <i class="fas fa-mobile-alt"></i>
                <span>+03{{data[0]?.indicative1}}-{{data[0]?.phone1}}</span>
            </p>
            <p class="text-footer">
                <i class="fas fa-mobile-alt"></i>
                <span>+03{{data[0]?.indicative2}}-{{data[0]?.phone2}}</span>
            </p>
        </div>
        <p class="text-footer">
            <i class="far fa-envelope"></i>
            <span>{{data[0]?.contactEmail}}</span>
        </p>
        <p class="text-footer">
            <i class="fas fa-map-marker-alt"></i>
            <span> {{data[0]?.mainAddress}}</span>
        </p>
    </div>
</div>
<div class="blue-footer">
    <p class="text-center copyright-footer">Copyright ILM © {{test | date:
        'yyyy'}} </p>
</div>